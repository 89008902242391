<template>
    <router-view />
<!--  <img alt="Vue logo" src="./assets/logo.png">-->
<!--  <HelloWorld msg="Welcome to Your Vue.js App"/>-->
<!--  <FooterCom msg="Welcome to Your Vue.js App"/>-->
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
// import FooterCom from './components/FooterCom.vue'
import Vconsole from 'vconsole'
import {isPrd} from '@/utils/index'
if(!isPrd()){
    // new Vconsole();
}
export default {
    name: 'App',
    components: {
    // HelloWorld,
    // FooterCom
    },
    mounted () {
        document.documentElement.style.fontSize =
            (document.documentElement.clientWidth > 1925 ? 1925: document.documentElement.clientWidth) / 10 + "px"
        window.onresize = function(){
            document.documentElement.style.fontSize =
                (document.documentElement.clientWidth > 1925 ? 1925: document.documentElement.clientWidth) / 10 + "px"
        }
    },

}
</script>

<style>
/*#app {*/
/*  font-family: Avenir, Helvetica, Arial, sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*  text-align: center;*/
/*  color: #2c3e50;*/
/*  margin-top: 60px;*/
/*}*/
*{
    padding:0;
    margin:0;
}
</style>
