import { createStore, createLogger } from 'vuex';
import wallet from './modules/wallet';
import login from './modules/login';
import vuexAlong from 'vuex-along';
import {walletInit} from "@/assets/js/sdk/init.wallet";
import {session} from "@/assets/js/sdk/session";
const debug = process.env.VUE_APP_NODE_ENV !== 'production'
// console.log('VUE_APP_NODE_ENV:',process.env.VUE_APP_NODE_ENV)
export default createStore({
    modules: {
        wallet,
        login,
    },
    state: {
        chainId: session.getSessionStorage('chainId'),
        account: session.getSessionStorage('account'),
    },
    getters: {},
    mutations: {
        chainId(state, id) {
            state.chainId = id;
            session.setSessionStorage('chainId', id);
        },
        account(state, val) {
            state.account = val;
            session.setSessionStorage('account', val)
        }
    },
    actions: {
        connect({commit, dispatch}, data) {
            return walletInit.connect(data).then(acc => {
                let isConnect = acc && acc.length > 0;
                let account = isConnect ? acc[0] : '';
                commit('account', account);
                return account;
            })
        }
    },
    strict: debug,
    plugins: debug ? [createLogger(),vuexAlong()] : [vuexAlong()]
})
