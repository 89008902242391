import {check,login} from '@/api/loc'
import {isPrd} from '@/utils'
const state = () => ({
    account: '',
    balance:'',
    chainId:isPrd()?56:97,
    check:'',
    sign:'',
    isLogin:'',
})


const getters = {
    getAccount(state){
        return state.account
    }
}


const actions = {
    async check ({ commit }) {
        const res = await check()
        if(res.data.code===5001){
            commit('setSign', res.data.data)
        }else if(res.data.code===200){
            commit('setSign', '')
        }
    },
    async login ({ commit }) {
        const res = await login()
        commit('setLogin', res.data.code)
    }
}

// mutations
const mutations = {
    setAccount (state, account) {
        state.account = account
    },
    setBalance (state, balance) {
        state.balance = balance
    },
    setCheck(state, check) {
        state.check = check
    },
    setSign(state, data) {
        state.sign = data
    },
    setLogin(state, data) {
        state.isLogin = data
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}