import Web3 from 'web3/dist/web3.min.js'


export const shortAddress = (address) => {
    return address.replace(/(.{2}).+(.{6})$/, '$1...$2')
}

export const isPrd = () => {
    // console.log(process.env.VUE_APP_NODE_ENV)
    return process.env.VUE_APP_NODE_ENV === 'production'
}
export const isTest = () => {
    // console.log(process.env.VUE_APP_NODE_ENV)
    return process.env.VUE_APP_NODE_ENV === 'test'
}

export const contractAddress=(ads)=>{
    if(ads==='slg'){
        return isPrd()?'0x2348b010Fa9c0Ce30Bb042D54c298a3411361a01':'0xaB844e9C80425AA696f800703Fb77FE8a0441d36'
        // return isPrd()?'0x2348b010Fa9c0Ce30Bb042D54c298a3411361a01':'0x2348b010Fa9c0Ce30Bb042D54c298a3411361a01'
    }
}
export const isMobile  = navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
export const toGame=()=>{
    if(isMobile){
        window.location.href=isPrd()?'https://play.loc.connector.games':'https://play.loc.connector.games'

    }else{
        const createSuperLabel=(url, id)=> {
            let a = document.createElement("a");
            a.setAttribute("href", url);
            a.setAttribute("target", "_blank");
            a.setAttribute("id", id);
            if(!document.getElementById(id)) {
                document.body.appendChild(a);
            }
            a.click();
        }
        createSuperLabel(
            isPrd()?'https://play.loc.connector.games':'https://play.loc.connector.games','new_id'
        )
        // window.open(isPrd()?'https://play.landofconquest.com/':'https://bsctestplay.landofconquest.com/')
    }
}
export const  getProvider=()=>{
    const provider = window.bitkeep && window.bitkeep.ethereum
    if(!provider){
        return window.open('https://bitkeep.com/download?type=0&theme=light')
    }
    return  provider
}
export const web3=(
    ()=>{
        if(localStorage.getItem('Gitkeep')!='undefined'&&localStorage.getItem('Gitkeep')){
            if(window.bitkeep && window.bitkeep.ethereum){
                console.log(window.bitkeep.ethereum)
                return  new  Web3(window.bitkeep.ethereum)
            }
        }
        else{
            let web3Provider=null
            if (window.ethereum) {
                web3Provider = window.ethereum;
            }
            else if (window.web3) {
                web3Provider = window.web3.currentProvider;
            }
            return new Web3(web3Provider)
        }
    }
)()

