class Session {
    constructor() {
    }
    setLocalStorage = (key, val) => {
        localStorage.setItem(key, val);
    }
    getLocalStorage = (key) => {
        return localStorage.getItem(key);
    }
    setSessionStorage = (key, val) => {
        sessionStorage.setItem(key, val);
    }
    getSessionStorage = (key) => {
        return sessionStorage.getItem(key);
    }
    clearSessionStorage = (key) => {
        sessionStorage.removeItem(key);
    }
}

const session = new Session();
export {session};

