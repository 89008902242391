import axios from '@/utils/axios'
export const check= async ()=>{
    axios.defaults.headers['PF-ADDRESS']=localStorage.getItem('account')||''
    axios.defaults.headers['PF-SIGN']=localStorage.getItem('sign')||''
    return axios.post('/loc/v1/biz/signature/check')
}
export const login= async ()=>{
    axios.defaults.headers['PF-ADDRESS']=localStorage.getItem('account')
    axios.defaults.headers['PF-SIGN']=localStorage.getItem('sign')||''
    return axios.post('/loc/v1/biz/signature/login')
}
export const getAddress= async ()=>{
    axios.defaults.headers['PF-ADDRESS']=localStorage.getItem('account')
    axios.defaults.headers['PF-SIGN']=localStorage.getItem('sign')||''
    return axios.get('/loc/v1/biz/deposit/address')
}
export const getList= async (params)=>{
    axios.defaults.headers['PF-ADDRESS']=localStorage.getItem('account')
    axios.defaults.headers['PF-SIGN']=localStorage.getItem('sign')||''
    return axios.get('/loc/v1/biz/coin-withdraw/list',{params})
}
export const getDepositList= async (params)=>{
    axios.defaults.headers['PF-ADDRESS']=localStorage.getItem('account')
    axios.defaults.headers['PF-SIGN']=localStorage.getItem('sign')||''
    return axios.get('/loc/v1/biz/coin-deposit/list',{params})
}
export const getWithdraw= async (id)=>{
    axios.defaults.headers['PF-ADDRESS']=localStorage.getItem('account')
    axios.defaults.headers['PF-SIGN']=localStorage.getItem('sign')||''
    return axios.post('/loc/v1/biz/coin-withdraw/withdraw',{id})
}
export const getNftList= async (data)=>{
    axios.defaults.headers['PF-ADDRESS']=localStorage.getItem('account')
    axios.defaults.headers['PF-SIGN']=localStorage.getItem('sign')||''
    return await axios.get('/loc/v1/biz/nft/list',{params:data})
}
export const getNftDetail= async (data)=>{
    axios.defaults.headers['PF-ADDRESS']=localStorage.getItem('account')
    axios.defaults.headers['PF-SIGN']=localStorage.getItem('sign')||''
    return axios.get('/loc/v1/biz/nft/detail',{params:data})
}
export const getinfo= async (data)=>{
    axios.defaults.headers['PF-ADDRESS']=localStorage.getItem('account')
    axios.defaults.headers['PF-SIGN']=localStorage.getItem('sign')||''
    return axios.get('/loc/v1/biz/user/info')
}
export const checkIp= async ()=>{
    return axios.get('/loc/v1/biz/country/check')
}


