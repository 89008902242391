import { createApp } from 'vue'
import Vue from "vue";
import App from './App.vue'
import {router} from './router'
import store from '@/store';
import {StaticUrl} from "@/assets/js/const";
// import '@/assets/css/css2.css'
// import '@/assets/css/bootstrap.min.css'
// import '@/assets/css/slick.css'
// import '@/assets/css/icofont.min.css'
// import '@/assets/css/animate.css'
// import '@/assets/css/nice-select.css'
// import '@/assets/css/magnific-popup.css'
// import '@/assets/css/style.css'

// import '@/assets/js/modernizr-3.7.1.min.js'
// import '@/assets/js/jquery-3.6.0.min.js'
// import '@/assets/js/jquery-migrate-3.3.2.min.js'
// import '@/assets/js/popper.js'
// import '@/assets/js/bootstrap.min.js'
// import '@/assets/js/slick.min.js'
// import '@/assets/js/wow.min.js'
// import '@/assets/js/jquery.nice-select.js'
// import '@/assets/js/jquery.magnific-popup.min.js'
// import '@/assets/js/jquery.counterup.min.js'
// import '@/assets/js/jquery-waypoints.js'
import ElementPlus from 'element-plus';
import VideoPlayer from 'vue-video-player'
import 'element-plus/theme-chalk/index.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
import '@/assets/css/element.scss';
// Object.defineProperty(Vue.prototype, "$static", {
//     value: StaticUrl,
//     writable: false
// });
const app = createApp(App)
app.use(store)
app.use(router)
app.use(ElementPlus)
app.use(VideoPlayer)
app.mount('#app')
AOS.init();
// createApp(App).mount('#app')
