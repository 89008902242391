import axios from 'axios';
const Axios = axios.create({
    timeout: 30000, 
    headers:{
        'PF-ADDRESS':localStorage.getItem('account')||'',
        'PF-SIGN':localStorage.getItem('sign')||''
    }
});
Axios.interceptors.request.use(function (config) {
    return config;
}, function (error) {
    return Promise.reject(error);
});

Axios.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    return Promise.reject(error);
});
export default Axios;
