import { createRouter,createWebHashHistory} from "vue-router";
const home = () => import("../homePage")
const home1 = () => import("../homePage1")
const login = () => import("@/view/users/loginPage")
const register = () => import("@/view/users/registerPage")
const BindMailbox = () => import("@/view/users/BindMailbox")
const nftPage = () => import("../view/nftPage/nftPage")
const landPage = () => import("../view/land/land")
const locPage = () => import("../view/locPage")
const users = () => import("../view/account/myWallet")
const wallet = () => import("../view/account/components/myWallet")
const account = () => import("../view/account/components/myAccount.vue")
const nft = () => import("../view/account/components/myNft")
const guild = () => import("../view/guild/gIndex.vue")
const landInfoPage = () => import("../view/landInfoPage")
const blankPage = () => import("../view/blankPage/blankPage.vue")
// const migrationPage = () => import("../view/migration/migrationPage");
const routes = [
    { path: "/", redirect: "/home" },
    {
        path: "/home",
        name: "home",
        component: home
    },
    {
        path: "/home1",
        name: "home1",
        component: home1
    },
    {
        path: "/login",
        name: "login",
        component: login
    },
    {
        path: "/register",
        name: "register",
        component: register
    },
    {
        path: "/guild",
        name: "guild",
        component: guild
    },
    {
        path: "/email",
        name: "email",
        component: BindMailbox
    },
    {
        path: "/nft",
        name: "nft",
        component: nftPage
    },
    {
        path: "/land",
        name: "LandPages",
        component: landPage
    },
    // {
    //     path: "/migration",
    //     name: "MigrationPage",
    //     component: migrationPage
    // },
    {
        path: "/loc",
        name: "loc",
        component: locPage
    },
    {
        path: "/blank",
        name: "blank",
        component: blankPage
    },
    {
        path: "/users",
        name:'users',
        component: users,
        children: [
            { path: '/', component: wallet },
            { path: 'wallet', component: wallet , name:'users/wallet',},
            {
                path: 'account',
                component: account,
            },
            {
                path: 'mynft',
                component: nft,
            },
        ],
    },
    {
        path: "/landInfo",
        name: "landInfo",
        component: landInfoPage
    }
]

// const scrollBehavior = function (to, from, savedPosition) {
//     if (savedPosition) {
//         return savedPosition
//     } else {
//         return {x: 0, y: 0}
//     }
// }
export const router = createRouter({
    history: createWebHashHistory(),
    routes: routes,
    // scrollBehavior:scrollBehavior(),
})
