const state = () => ({
    users:{} ,
})


const getters = {
}


const actions = {
}

// mutations
const mutations = {
    setUsers (state, user) {
        state.users=user
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}